import * as React from 'react';
import { graphql } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
} from '@/../graphql-types';
import Layout from '@/components/layout/Layout';
import styled from 'styled-components';
import Button from '@/components/layout/Button';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';

const LinesWrapper = styled.div``;
const H1Huge = styled.h1`
  font-size: 10em;
  line-height: 1;
`;
const Figcaption = styled.div`
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 20px solid ${(props) => props.theme.color.primaryMain};
    position: absolute;
    top: -20px;
    left: calc(1rem + 16px);
  }
`;

export const query = graphql`
  query NotFoundQuery {
    contentfulComponentSiteHeader(
      node_locale: { eq: "en-US" }
      name: { eq: "Miovision.com - Site Header" }
    ) {
      ...ContentfulComponentSiteHeaderFragment
    }
    contentfulComponentSiteFooter(
      node_locale: { eq: "en-US" }
      name: { eq: "Miovision.com - Site Footer" }
    ) {
      ...ContentfulComponentSiteFooterFragment
    }
  }
`;

// markup
const NotFoundPage = (props) => {
  const { location, data } = props;

  const { locale } = useLocalization();

  const pageHeader = data?.contentfulComponentSiteHeader;
  const pageFooter = data?.contentfulComponentSiteFooter;

  const imageURL =
    'https://images.ctfassets.net/imvg0drj972v/6zmLD8yRfbw25VPd0j71iy/9f53f10ff597142b64e79baac3f803c3/404.png?h=330';

  // return '404';
  return (
    <Layout
      location={location}
      header={pageHeader as ContentfulComponentSiteHeader}
      footer={pageFooter as ContentfulComponentSiteFooter}
    >
      <div className="max-w-6xl m-auto relative md:pt-md mb-lg">
        <LinesWrapper className="hidden md:block absolute top-0 left-0">
          <svg
            width="49"
            height="150"
            viewBox="0 0 49 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 2L25 104"
              stroke="#00A566"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <line
              x1="1.5"
              y1="-1.5"
              x2="14.5"
              y2="-1.5"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 24.5 133.215)"
              stroke="#00A566"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <circle
              r="2"
              transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 25.5 147.215)"
              fill="#00A566"
            />
            <path
              d="M47 64L47 2"
              stroke="#006DFF"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M2 42L2 2"
              stroke="#FFB700"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </LinesWrapper>
        <div className="flex flex-col-reverse md:flex-row px-sm md:pl-lg">
          <div>
            <figure>
              <img src={imageURL} className="mb-8 block mx-auto" />
              <Figcaption className="relative bg-primaryMain text-white px-8 py-4 rounded-sm text-center md:text-left">
                One of our Scouts had a visitor in Singapore
              </Figcaption>
            </figure>
          </div>
          <div className="text-center md:text-left md:w-2/5 mb-8 md:pl-12">
            <H1Huge className="text-primaryMain mb-4">404</H1Huge>
            <p className="text-primaryMain text-4xl font-light">
              Sorry, we can’t find that page
            </p>
            <p className="text-primaryMain my-6">
              Even if everyone is looking for it.
            </p>
            <Button variant="Outline">
              <LocalizedLink language={locale} to="/">
                <span>Homepage</span>
              </LocalizedLink>
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
